$modern-modal-size-sm: 328px;
$modern-modal-size: 600px;
$modern-modal-size-lg: 800px;
$modern-modal-size-xl: 1200px;
$modern-modal-size-2xl: 1440px;
$modern-modal-outside-button-size: 40px;

$modern-modal-lg-max: 1260px;
$modern-modal-xl-max: 1480px;
$modern-modal-2xl-min: $modern-modal-xl-max + 1;

.shp-modern-modal {
  &.modal-same-padding .modal-content .modal-body {
    padding: $modern-gap-md;
  }

  &.modal-align-center.modal.fade .modal-dialog {
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }

  &.modal-align-right.modal.fade .modal-dialog {
    margin: 0 0 0 auto;

    @media (max-width: $screen-mobile-max) {
      margin: 0;
    }
  }

  &.modal-no-body {
    .modal-content {
      .modal-body {
        padding: 0;
      }
    }
  }

  &.modal-no-radius {
    .modal-content {
      border-radius: 0;
    }
  }

  &.modal-max-content {
    .modal-dialog {
      width: max-content;
    }
  }

  &.modal-no-header {
    .modal-content {
      .modal-header {
        padding: 0;
      }
    }
  }

  &.modal-warning {
    .modal-content {
      border: 2px solid $modern-warning;
    }
  }

  .modal-content {
    position: relative;
    border: none;
    border-radius: $modern-radius-lg;
    font-family: "DIN 2014 Rounded", Arial, sans-serif;
    font-size: $modern-font-md;
    font-weight: $font-regular;
    line-height: $modern-line-height;
    letter-spacing: 0;
    word-break: break-word;
    background-color: $modern-layer;

    .modal-header {
      padding: $modern-gap-md $modern-gap-lg 0 $modern-gap-md;
      border: none;
    }

    .modal-header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .modal-title {
        font-weight: $font-demi;
        font-size: $modern-font-h3;
        line-height: $modern-line-height-h3;
      }
    }

    .modal-body {
      position: static;
      padding: $modern-gap-md $modern-gap-lg $modern-gap-md $modern-gap-lg;

      .modal-no-padding {
        margin-right: -$modern-gap-lg;
        margin-left: -$modern-gap-md;
        padding: $modern-gap-md $modern-gap-lg $modern-gap-md $modern-gap-md;
      }
    }

    .modal-footer {
      padding: 0 $modern-gap-lg $modern-gap-lg $modern-gap-md;
      border: none;
      text-align: left;
    }

    .modal-header-close-button {
      align-self: flex-start;
      text-align: right;
      margin-top: $modern-gap-sm;
    }

    .modal-body-close-button {
      position: absolute;
      right: 16px;
      top: 16px;
    }

    .modal-header-close-button, .modal-body-close-button {
      font-size: $modern-font-icon;
      color: $modern-subtext;
      cursor: pointer;

      &:hover {
        color: $modern-text-black;
      }
    }
  }

  .modal-sm {
    width: $modern-modal-size-sm;
  }

  .modal-lg {
    width: $modern-modal-size-lg;
  }

  .modal-xl {
    width: $modern-modal-size-xl;
  }

  .modal-2xl {
    width: $modern-modal-size-2xl;
  }

  &__full-screen {
    z-index: 2100 !important;
    .modal-dialog {
      width: 100%;
      margin: 0;
    }
  }

  &.modal-placement-background .modal-content {
    background: $modern-placement-background;
  }

  &.modal-adaptive .modal-dialog {
    width: fit-content;
  }

  &.modal-placement-bg .modal-content {
    background-color: $bg-color;
  }

  &.modal-mobile-full-screen.modal.fade .modal-dialog {
    @media (max-width: $screen-mobile-max) {
      margin: 0;

      .modal-content {
        position: relative;
        min-height: var(--window-height);
        width: 100vw;
        border-radius: 0;
      }

      .modal-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }

  &.modal-outside-icon {
    .modal-content {
      position: static;
    }

    .modal-body-close-button {
      right: 100%;
      width: $modern-modal-outside-button-size;
      height: $modern-modal-outside-button-size;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $modern-radius-lg 0 0 $modern-radius-lg;
      background: $modern-primary;
      color: $modern-text-white;
    }
  }

  &.modal-overflow-body {
    .modal-content {
      max-height: calc(100vh - 60px);
      display: flex;
      flex-direction: column;
    }
    .modal-body {
      overflow: auto;
      padding: 0 $modern-gap-lg 0 $modern-gap-md;
      margin: $modern-gap-md 0 $modern-gap-lg 0;
    }
  }

  @media (min-width: $modern-modal-2xl-min) {
    .modal-dialog.modal-2xl {
      width: $modern-modal-size-2xl;
    }
  }

  @media (max-width: $modern-modal-xl-max) {
    .modal-dialog.modal-2xl,
    .modal-dialog.modal-xl {
      width: $modern-modal-size-xl;
    }
  }

  @media (max-width: $modern-modal-lg-max) {
    .modal-dialog.modal-2xl,
    .modal-dialog.modal-xl,
    .modal-dialog.modal-lg {
      width: $modern-modal-size-lg;
    }
  }

  @media (max-width: $screen-tablet-max) {
    .modal-dialog.modal-2xl,
    .modal-dialog.modal-xl,
    .modal-dialog.modal-lg {
      width: $modern-modal-size;
    }
  }

  @media (max-width: $screen-mobile-max) {
    .modal-dialog.modal-2xl,
    .modal-dialog.modal-xl,
    .modal-dialog.modal-lg,
    .modal-dialog {
      width: $modern-modal-size-sm;

      .modal-header {
        padding: $modern-gap-md $modern-gap 0 $modern-gap;
      }
      .modal-body {
        padding: $modern-gap $modern-gap $modern-gap-md $modern-gap;
      }
      .modal-footer {
        padding: 0 $modern-gap $modern-gap-md $modern-gap;
      }
    }
  }
}

.modal-backdrop.fade.in {
  opacity: 0.25;
}

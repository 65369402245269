.draggable-card {
  padding-top: $modern-gap-condensed;
  padding-bottom: $modern-gap-xs;
  border-top: 1px solid $modern-line-light;

  &:first-child {
    border-top: none;
  }

  &.sortable-placeholder {
    border: 1px dashed $modern-primary-pressed;
    background-color: $modern-background;
    border-radius: 10px;
  }
}

@use "sass:meta";
@use "sass:math";

@mixin flex-row {
  display: flex;
  flex-flow: row wrap;

  .flex-row-col {
    display: flex;
    flex-flow: column;
  }

  .flex-row-inner {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
  }
}

.flex-0 {
  flex: 0;
}

.flex-05 {
  flex: 0.5;
}

.flex-1 {
  flex: 1;
}

.flex-1000 {
  flex: 1000;
}

.flex-1-auto {
  flex: 1 0 auto;
}

.inline-flex {
  display: inline-flex;
}

.flex-8 {
  flex: 8 0;
}

.flex-8-auto {
  flex: 8 0 auto;
}

.flex-stretch {
  align-items: stretch;
}

.flex-no-stretch {
  align-items: flex-start;
}

.flex-vertical-start {
  align-items: flex-start;
}

.flex-vertical-center {
  align-items: center;
}

.flex-vertical-baseline {
  align-items: baseline;
}

.flex-vertical-bottom {
  align-items: flex-end;
}

.flex-start {
  justify-content: start;
}

.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-pull-right {
  align-self: flex-end;
}

.flex-pull-left {
  align-self: flex-start;
}

.flex-self-start {
  align-self: flex-start;
}

.flex-self-end {
  align-self: flex-end;
}

.shp-modal-full-width .modal-header {
  width: 100%;
}

@media (min-width: 767px) {
  .flex-sm {
    display: flex;
  }
}

@media (min-width: 978px) {
  .flex-md {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .flex-lg {
    display: flex;
  }

  .flex-row-lg {
    @include flex-row;
  }
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-8 {
    flex-grow: 8
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-small {
  flex-shrink: 0.5;
}

.flex-no-resizable {
  flex-shrink: 0;
  flex-grow: 0;
}

.flex-resizable {
  flex-shrink: 1;
  flex-grow: 1;
}

.flex-row-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex {
    display: flex;
}

.flex-mobile-to-column {
  display: flex;

  @media (max-width: $screen-mobile-max) {
    flex-direction: column;

    [class^='flex-basis-'] {
      flex-basis: 100%;
    }
  }
}

.flex-mobile-column-reverse {
  display: flex;

  @media (max-width: $screen-mobile-max) {
    flex-direction: column-reverse;
  }
}

.flex-mobile-vertical-center {
  @media (max-width: $screen-mobile-max) {
    align-items: center;
  }
}

.flex-mobile-end {
  @media (max-width: $screen-mobile-max) {
    justify-content: flex-end;
  }
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-gap-xs {
  gap: $grid-size;
}

.flex-gap-sm {
  gap: $grid-size * 2;
}

.flex-gap {
  gap: $grid-size * 3;
}

.flex-gap-md {
  gap: $grid-size * 4;
}

.flex-gap-lg {
  gap: $grid-size * 5;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.inline-flex {
    display: inline-flex;
}

.flex-row {
  @include flex-row;
}

.stretch-column {
  flex-grow: 1;
}

.stretch-column-content {
  display: flex;
  flex-direction: column;
}

.flex-lg {
    @media (min-width: $screen-lg) {
        display: flex;
    }
}

.flex-md {
    @media (min-width: $screen-md) {
        display: flex;
    }
}

.flex-sm {
    @media (min-width: $screen-sm) {
        display: flex;
    }
}

.flex-margin-sm ~ .flex-margin-sm {
  margin-left: math.div($line-padding, 2);
}

.flex-minimize {
  min-width: 0;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-20 {
  flex-basis: 20%;
}

.flex-basis-33 {
  flex-basis: 33.33%;
}

.flex-basis-66 {
  flex-basis: 66.66%;
}

.flex-basis-15 {
  flex-basis: 15%;
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-60 {
  flex-basis: 60%;
}

@mixin flex-gap($gaps...) {
  @each $gap in $gaps {
    .gap-#{$gap} {
      gap: $gap
    }
  }
}

@include flex-gap(
  $modern-gap,
  $modern-gap-xxs,
  $modern-gap-xs,
  $modern-gap-sm,
  $modern-gap-md,
  $modern-gap-lg,
  $modern-gap-xl,
  $modern-gap-2xl,
);

@mixin gap-sizes($args...) {
  @each $name, $size in meta.keywords($args) {
    .gap-#{$name} {
      gap: $size;
    }
  }
}

@include gap-sizes(
  $xxs: math.div($line-padding, 8),
  $xs: math.div($line-padding, 4),
  $sm: math.div($line-padding, 2),
  $standart: $line-padding,
  $md: math.div($line-padding * 3, 2),
  $lg: $line-padding * 2,
  $xl: $line-padding * 4
);


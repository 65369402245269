.block {
    display: block;
}

.no-padding{
    padding: 0;
}

.text-left {
    text-align: left;
}

.bold {
    font-weight: bold;
}

.semi-bold {
    font-weight: 500;
}

.demi-bold {
  font-weight: 600;
}

.text-md {
  font-size: $font-size-small
}

.text-lg {
  font-size: $font-size-large;
}

.text-xxl {
  font-size: $font-size-h3;
}


.shp-full-width {
    width: 100%;
    vertical-align: top;
}

textarea.shp-full-width {
    width: 100%;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


.full-height {
    height: 100%;
}

.clickable {
    cursor: pointer;
}

.movable, .movable .clickable{
    cursor: move;
}

.nowrap {
    white-space: nowrap;
}

.ws-normal {
  white-space: normal;
}

.more-info {
    font-style: italic;
}

.break_words {
    word-wrap: break-word;
}

.break_all {
  word-break: break-all;
}

.shp-clear {
  clear:both
}

.text-white {
  color: white;
}

.text-jungle-green {
  color: $jungle-green-color;
}

.text-top {
    vertical-align: text-top;
}

.to-top {
    vertical-align: top;
}

.vertical-bottom {
    vertical-align: bottom;
}

.text-bottom {
    vertical-align: text-bottom;
}

.to-middle, .vertical-center {
    vertical-align: middle;
}


.inline {
    display: inline;
}

.relative, .relative-position {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

.overflow-visible {
    overflow: visible;
}

.scroll-y {
    overflow-y: scroll;
}

.scroll-y-auto {
    overflow-y: auto;
}

.scroll-x {
    overflow-x: scroll;
}

.scroll-x-auto {
    overflow-x: auto;
}

.scroll-x-hidden {
    overflow-x: hidden;
}

.no-float {
    float: none;
}

.table-scroll {
    width:100%;
    height:100%;
}

.lead.small {
    font-size: 16px;
}

.small {
    font-size: 12px;
}

.to-bottom {
    bottom: 0;
}


.to-right {
    right: 0;
}

.no-top {
    margin-top: 0;
}

.no-top-padding {
    padding-top: 0;
}

input[type="radio"].no-top, input[type="checkbox"].no-top {
    margin-top: 0;
    margin-bottom: 2px;
}

.rounded {
    border-radius: 4px;
}

.circle {
    border-radius: 50%;
}

.no-left-margin {
    margin-left: 0;
}

.uppercase {
    text-transform: uppercase;
}

.no-side-margin {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -10px;
}

.text-center, td.text-center, th.text-center{
    text-align: center;
}

@media (min-width: $screen-lg-min) {
  .text-center-lg {
    text-align: center;
  }
}

@media (min-width: $screen-md-min) {
  .text-center-md {
    text-align: center;
  }
}

@media (min-width: $screen-sm-min) {
  .text-center-sm {
    text-align: center;
  }
}

.text-right, .table th.text-right, .table td.text-right {
    text-align: right;
}

.table {
    display: table;
}

.checkbox-column {
  width: 24px;
}

.h4 {
    font-size:18px;
    line-height:20px;
}

.inline-table, .table.inline-table {
    display: inline-table;
}


.table, .display-table{
    display:table;
}

.table {
  &.no-border {
    td {
      border: none;
    }
  }
}

.table.shp-table-responsive {
  white-space: nowrap;
  overflow-x: auto;
}

.table.shp-table-padded {
  > tbody, > thead {
    > tr > :first-child {
      padding-left: $line-padding;
    }
    > tr > :last-child {
      padding-right: $line-padding;
    }
  }
}

.table.shp-table-condensed {
  > thead, > tbody {
    > tr {
      > th, >td {
        padding: 6px 5px;
      }
    }
  }
}

.table.shp-table-extreme-borders {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.shp-table-row{
    display:table-row;
}

.sticky_column {
  position: sticky;
  left: 0;
  z-index: 100;
  background: white;
}

.cell{
    display: table-cell;
}

.overflow-hidden {
    overflow: hidden;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.initials__limited {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 240px;
  //display: inline-block;
}


.col-square, .col-rectangle {
    position: relative;
    overflow: hidden;
}

.col-square {
    padding-bottom:100%;
}

.col-rectangle, .dropzone.col-rectangle {
    padding-bottom:75%;
}

.col-square > div, .col-rectangle > div {
    position: absolute;
}

.row-vertical {
  &:before, &:after {
    display: none;
  }

  display: flex;
  flex-wrap: wrap;
  margin-top: -16px;
  margin-bottom: -16px;

  & + & {
    @media (max-width: $screen-md-min) {
      margin-top: 8px;
    }
  }

  @media (max-width: $screen-md-min) {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .col-md-6 {
    padding-top: 16px;
    padding-bottom: 16px;

    @media (max-width: $screen-md-min) {
      flex: 1 0 100%;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .col-md-12 {
    padding-top: 16px;
    padding-bottom: 16px;

    @media (max-width: $screen-md-min) {
      flex: 1 0 100%;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

pre.invisible_pre {
    overflow: auto;
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 14px;
    font-family: $fonts;
    white-space: pre-wrap;
}

pre.monospace {
    font-size: 14px;
    font-family: $fonts-monospace;
}

.monospace-font {
    font-family: $fonts-monospace;
}

.monospace-font-md {
  font-family: $fonts-monospace;
  font-size: $font-md;
}

.monospace-font-lg {
  font-family: $fonts-monospace;
  font-size: $font-lg;
}

.vertical-line {
    height: 100%;
    visibility: hidden;
    display: inline-block;
    vertical-align: middle;
}

.no-resize {
    resize: none;
}

.vertical-resize {
  resize: vertical;
}

.under-line {
    text-decoration: underline;
}

.underline-dotted {
  text-decoration: underline dotted;
}

.underline-dashed-border {
  border-bottom: 1px dashed;
}

.preview-row {
    cursor: pointer;
}

.only-pupil {
    display: none;
}

.pupil-content .only-pupil {
    display: inherit;
}

.pupil-content a.only-pupil {
    display: inline;
}

.only-representative {
    display: none;
}

.representative-content .only-representative {
    display: inherit;
}

.representative-content a.only-representative {
    display: inline;
}

.pagination {
    margin-top: 0;
    margin-bottom: 10px;
}

.inline-dropdown {
    vertical-align: baseline;
}

.no-bottom, input[type="text"].no-bottom {
    margin-bottom: 0;
}

.mw-100 {
  max-width: 100%;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto !important;
}

.w-30p {
    width: 30px;
}

.h-100vh {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.mh-250p {
  max-height: 250px;
}

.w-100p {
  width: 100px;
}

.w-80p {
  width: 80px;
}

.table-hover>tbody>tr:hover > .no-hover {
    background: white;
}

.table-centered > tr > td {
    vertical-align: middle;
}

.table-small .hide-if-small {
    display: none;
}

.btn-info .text-muted {
    color: lighten($text-muted, 50%)
}

.radio-list[disabled] {
    .pretty[p-checkbox]{
        background: $disabled-input-color;
    }
}

.bg-white {
    background: $modern-layer;
}

.break_words {
    word-break: break-word;
}

.overflow-auto {
    overflow: auto
}

.overflow-y-auto {
  overflow-y: auto;
}

.fit-content {
    width: fit-content;
}

.h-fit-content {
    height: fit-content;
}

.flex-pull-right {
    margin-left: auto;
}
.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dotted-link {
    color: $navy-text;
    border-bottom: 1px $navy-text dotted;
    cursor: pointer;
}

.font-weight-bold {
    font-weight: bold;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.comma-separated {
  > * {
    display: inline;

    &:after {
      content: ", ";
      display: inline;
    }

    &:last-child:after {
      content: "";
    }
  }
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.semicolon-separated {
  > *:after {
    content: "; ";
    display: inline-flex;
  }

  > :last-child:after {
    content: "";
  }
}

.pipe-separated {
  > *:after {
    content: " | ";
    display: inline-flex;
  }

  > :last-child:after {
    content: "";
  }
}

.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: gray;
}

.bottom-line-separated {
  > * {
    border-bottom: 1px solid $modal-footer-border-color;
  }

  > :last-child {
    border-bottom: none;
  }
}

.content-bordered {
  border: 1px solid $modal-footer-border-color
}

.line-height {
  line-height: $line-height;
}

.content-center {
  margin: 0 auto;
}

.boxed-xs {
  max-width: 360px;
}

.boxed-lg {
  max-width: 1200px;
}

.boxed-extra {
  min-width: 1600px;
}

.stick_to_bottom {
  margin-top: auto;
}

.min-h-initial {
  min-height: initial !important;
}

pre.stderr {
  border-color: $modern-error;
}


.only-desktop-visible.only-desktop-visible {
  @media (min-width: $screen-desktop-min) {
    display: initial;
  }

  @media (max-width: $screen-tablet-max) {
    display: none;
  }
}

.only-desktop-hidden.only-desktop-hidden {
  @media (min-width: $screen-desktop-min) {
    display: none;
  }

  @media (max-width: $screen-tablet-max) {
    display: initial;
  }
}

.w-max-content {
  width: max-content;
}

.bg-disabled {
  background: $modern-grey-soft-background;
}

.rounded_avatar {
  border-radius: 200px;
}

.break-spaces {
  white-space: break-spaces;
}

.word-break-normal {
  word-break: normal;
}

ul.list-type-square {
  list-style-type: square;
}

.visibility-hidden {
  visibility: hidden;
}

.d-none {
  display: none !important;
}

.d-md-none {
  @media (min-width: $screen-md-min) {
    display: none !important;
  }
}

.d-lg-none {
  @media (min-width: $screen-lg-min) {
    display: none !important;
  }
}

.d-sm-none {
  @media (min-width: $screen-sm-min) {
    display: none !important;
  }
}
